import React from 'react';

import './hero.scss';

const Hero = () => {

    return (
        <div className="c-story-hero" data-animate-trigger>
            <div data-animate-target
                 data-animate-from={JSON.stringify({opacity: 0})}
                 data-animate-to={JSON.stringify({opacity: 1, duration: 1, ease: 'power3.out'})}
            >
                <h1 className="c-story-hero__heading">Náš příběh</h1>
            </div>
        </div>
    )
};

export default Hero
