import React from "react"
import Layout from '../components/Layout';
import Hero from '../components/story/Hero';
import Timeline from '../components/story/Timeline';
import CTAContact from '../components/CTAContact';
import SEO from '../components/seo';

const OutStory = () => {

    return (
        <Layout>
            <SEO title="Prohlédněte si náš příběh | Business Elements" />

            <Hero />

            <Timeline />


            <CTAContact />

        </Layout>
    )
};

export default OutStory
