import { useStaticQuery, graphql } from 'gatsby';

export const useTimelineData = () => {
    return useStaticQuery(graphql`
        query {
          newBrand: file(relativePath: { eq: "story/newbrand.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 320, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          newOffice: file(relativePath: { eq: "story/newoffice.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 480, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          vogue: file(relativePath: { eq: "story/vogue.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 320, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          nv500m: file(relativePath: { eq: "story/nv500m.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 320, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          vse: file(relativePath: { eq: "story/vse.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 320, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          mediasales: file(relativePath: { eq: "story/mediasales.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 480, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          webtop100: file(relativePath: { eq: "story/webtop100.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 480, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          malltv: file(relativePath: { eq: "story/malltv.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 480, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          netvision: file(relativePath: { eq: "story/netvision.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 480, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          burda: file(relativePath: { eq: "story/burda.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 700, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
          golf: file(relativePath: { eq: "story/golf.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 320, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
        }
    `);
};
