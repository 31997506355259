import React from 'react';
import Img from 'gatsby-image';
import {Link} from 'gatsby';

import './timeline.scss';
import {useTimelineData} from './query';

const Timeline = () => {
    const data = useTimelineData();

    return (
        <div className="c-timeline">
            <div className="c-timeline__inner">
                <div data-animate-trigger>
                    <div data-animate-target
                         data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                         data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, ease: 'power3.out'})}
                    >
                        <p className="c-timeline__year">2020</p>
                    </div>
                    <div data-animate-target
                         data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                         data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, delay: .2, ease: 'power3.out'})}
                    >
                        <p className="c-timeline__desc">Nová identita a nový web</p>
                    </div>
                    <div data-animate-target
                         data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                         data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, delay: .4, ease: 'power3.out'})}
                    >
                        <figure className="c-timeline__figure c-timeline__figure--after c-timeline__figure--sm">
                            <Img className='o-image' fluid={data.newBrand.childImageSharp.fluid} />
                        </figure>
                    </div>
                    <div data-animate-target
                         data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                         data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, delay: .6, ease: 'power3.out'})}
                    >
                        <p className="c-timeline__desc">Nově jsme se usídlili v nových kancelářích v pražském Karlíně v Praga Studios</p>
                    </div>
                    <div data-animate-target
                         data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                         data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, delay: .8, ease: 'power3.out'})}
                    >
                        <figure className="c-timeline__figure c-timeline__figure--md">
                            <Img className='o-image' fluid={data.newOffice.childImageSharp.fluid} />
                        </figure>
                    </div>
                </div>

                <div data-animate-trigger>
                    <div data-animate-target
                         data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                         data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, ease: 'power3.out'})}
                    >
                        <p className="c-timeline__year">2019</p>
                    </div>
                    <div data-animate-target
                         data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                         data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, delay: .2, ease: 'power3.out'})}
                    >
                        <p className="c-timeline__desc">Pomáhali jsme s nastavením digitální a obchodní strategie, business inteligence a procesů ve společnosti V24 Media, která je vydavatelem časopisu <a
                    className="o-link o-link--red" href="https://www.vogue.cz/" target="_blank" rel="nofollow noreferrer">VOGUE</a> na CZ/SK trhu.</p>
                    </div>
                    <div data-animate-target
                         data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                         data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, delay: .4, ease: 'power3.out'})}
                    >
                        <figure className="c-timeline__figure c-timeline__figure--after c-timeline__figure--sm">
                            <Img className='o-image' fluid={data.vogue.childImageSharp.fluid} />
                        </figure>
                    </div>
                </div>

                <div data-animate-trigger>
                    <div data-animate-target
                         data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                         data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, ease: 'power3.out'})}
                    >
                        <p className="c-timeline__desc">V partnerské společnosti <a
                    className="o-link o-link--red" href="https://www.net-vision.cz/" target="_blank" rel="nofollow noreferrer">Netvision agency</a> jsme získali milestone 500 spravujících klientů, kterým pomáháme efektivně investovat do marketingu.</p>
                    </div>
                    <div data-animate-target
                         data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                         data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, delay: .2, ease: 'power3.out'})}
                    >
                        <figure className="c-timeline__figure c-timeline__figure--figure-after c-timeline__figure--sm">
                            <Img className='o-image' fluid={data.nv500m.childImageSharp.fluid} />
                        </figure>
                    </div>

                    <div data-animate-target
                         data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                         data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, delay: .2, ease: 'power3.out'})}
                    >
                        <figure className="c-timeline__figure c-timeline__figure--after">
                            <div className="c-timeline__figure-half">
                                <div className="c-timeline__figure-half-img">
                                    <Img className='o-image' fluid={data.vse.childImageSharp.fluid} />
                                </div>
                                <div className="c-timeline__figure-half-content">
                                    <div data-animate-target
                                         data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                                         data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, delay: .4, ease: 'power3.out'})}
                                    >
                                        <p>V roce 2019 jsme také zahájili dobrovolnou spolupráci s <a
                                        className="o-link o-link--red" href="https://nf.vse.cz/homepage/absolventi/vyznamni-absolventi/" target="_blank" rel="nofollow noreferrer">Vysokou školou ekonomickou</a> v Praze. Pomáháme studentům k cestě k úspěchu a navazujeme dílčími workshopy.</p>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </div>
                </div>

                <div data-animate-trigger>
                    <div data-animate-target
                         data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                         data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, ease: 'power3.out'})}
                    >
                        <p className="c-timeline__desc">Na začátku roku jsme hrdě přijali pozvánku na konferenci <a
                    className="o-link o-link--red" href="https://www.tuesday.cz/akce/czech-internet-forum-2019/program/" target="_blank" rel="nofollow noreferrer">Czech Internet Forum</a>, kde jsme realizovali přednášku o inovacích a strategiích v obchodě a médiích – Media&Sales (Re)fresh.</p>
                    </div>
                    <div data-animate-target
                         data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                         data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, delay: .2, ease: 'power3.out'})}
                    >
                        <figure className="c-timeline__figure c-timeline__figure--figure-after c-timeline__figure--md">
                            <Img className='o-image' fluid={data.mediasales.childImageSharp.fluid} />
                        </figure>
                    </div>

                    <div data-animate-target
                         data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                         data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, delay: .2, ease: 'power3.out'})}
                    >
                        <figure className="c-timeline__figure c-timeline__figure--after">
                            <div className="c-timeline__figure-half">
                                <div className="c-timeline__figure-half-img">
                                    <Img className='o-image' fluid={data.webtop100.childImageSharp.fluid} />
                                </div>
                                <div className="c-timeline__figure-half-content">
                                    <div data-animate-target
                                         data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                                         data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, delay: .4, ease: 'power3.out'})}
                                    >
                                        <p>Při spolupráci s MallTV jsme získali v přestižní soutěži WebTop100 2.místo v kategorii Obsahový marketing s projektem „<a
                                    className="o-link o-link--red" href="https://www.lupa.cz/clanky/zavodni-akademie-byla-zabava-ale-take-prilezitost-pro-propagaci-znacek/" target="_blank" rel="nofollow noreferrer">Závodní akademie</a>“.</p>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </div>
                </div>

                <div data-animate-trigger>
                    <div data-animate-target
                         data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                         data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, ease: 'power3.out'})}
                    >
                        <p className="c-timeline__desc">Ve společnosti Mall Group pomáháme s launchem nové internetové televize <a
                    className="o-link o-link--red" href="https://www.malltv.cz/" target="_blank" rel="nofollow noreferrer">MallTV</a> pro český a slovenský trh. Získáváme klienty z řad TOP250 klientů MallGroup a nastavujeme kompletní obchodní strategii směrem do trhu.</p>
                    </div>
                    <div data-animate-target
                         data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                         data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, delay: .2, ease: 'power3.out'})}
                    >
                        <figure className="c-timeline__figure c-timeline__figure--md">
                            <Img className='o-image' fluid={data.malltv.childImageSharp.fluid} />
                        </figure>
                    </div>
                </div>

                <div data-animate-trigger>
                    <div data-animate-target
                         data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                         data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, ease: 'power3.out'})}
                    >
                        <p className="c-timeline__year">2018 a historie</p>
                    </div>
                    <div data-animate-target
                         data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                         data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, delay: .2, ease: 'power3.out'})}
                    >
                        <p className="c-timeline__desc">V roce 2018 jsme pomohli vzniknout partnerské společnosti <a
                    className="o-link o-link--red" href="https://www.net-vision.cz/" target="_blank" rel="nofollow noreferrer">Netvision agency</a>, která je úznávanou digitální agenturou v prostředí SME&SMB. </p>
                    </div>
                    <div data-animate-target
                         data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                         data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, delay: .4, ease: 'power3.out'})}
                    >
                        <figure className="c-timeline__figure c-timeline__figure--after c-timeline__figure--md">
                            <Img className='o-image' fluid={data.netvision.childImageSharp.fluid} />
                        </figure>
                    </div>
                </div>

                <div data-animate-trigger>
                    <div data-animate-target
                         data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                         data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, ease: 'power3.out'})}
                    >
                        <p className="c-timeline__desc"><a
                    className="o-link o-link--red" href="http://www.burda.cz/" target="_blank" rel="nofollow noreferrer">Burda International Czech</a> patři k největším vydavatelstvím lifestylových titulů jako například Elle, Marianne či MarieClaire. Zde jsme pomáhali s restrukturalizací digitální strategie, napojení na všechny mediální agentury a implementaci 360 obchodního modelu</p>
                    </div>
                    <div data-animate-target
                         data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                         data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, delay: .2, ease: 'power3.out'})}
                    >
                        <figure className="c-timeline__figure c-timeline__figure--figure-after">
                            <Img className='o-image' fluid={data.burda.childImageSharp.fluid} />
                        </figure>
                    </div>

                    <div data-animate-target
                         data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                         data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, delay: .2, ease: 'power3.out'})}
                    >
                        <figure className="c-timeline__figure">
                            <div className="c-timeline__figure-half">
                                <div className="c-timeline__figure-half-img">
                                    <Img className='o-image' fluid={data.golf.childImageSharp.fluid} />
                                </div>
                                <div className="c-timeline__figure-half-content">
                                    <div data-animate-target
                                         data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                                         data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, delay: .4, ease: 'power3.out'})}
                                    >
                                        <p>Společně s RK Evropa, která má přes 30 poboček v ČR, jsme realizovali prestižní <a
                                        className="o-link o-link--red" href="http://www.development-tour.cz/" target="_blank" rel="nofollow noreferrer">developerskou golf tour</a>. Tato tour je již tradicí v tomto oboru a hostí více jak 300+ byznysmenů a partnerů túry.</p>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </div>

                    <div data-animate-target
                         data-animate-from={JSON.stringify({y: 50, opacity: 0})}
                         data-animate-to={JSON.stringify({y: 0, opacity: 1, duration: 1, delay: .6, ease: 'power3.out'})}
                    >
                        <p className="c-timeline__back-btn-wrap"><Link to={'/#kdo-jsme'} className="o-button">Zpět na Kdo jsme</Link></p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Timeline
