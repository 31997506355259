import {graphql, useStaticQuery} from 'gatsby';

export const useImages = () => {
    return useStaticQuery(graphql`
    query {
      bgImg: file(relativePath: { eq: "cta-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
    }
  `);
};
