import React, {useContext} from 'react';
import {GlobalDispatchContext} from '../../context/GlobalContextProvider';
import {useImages} from './images';
import Img from 'gatsby-image';

import './cta-contact.scss';

const CTAContact = () => {
    const dispatch = useContext(GlobalDispatchContext);
    const {bgImg} = useImages();

    return (
        <div className='c-cta-contact' data-animate-trigger>
            <div className="c-cta-contact__bg"
                 data-animate-target
                 data-animate-from={JSON.stringify({y: 20, opacity: 0})}
                 data-animate-to={JSON.stringify({y: 0, opacity: 1})}
            >
                <Img className='o-image' fluid={bgImg.childImageSharp.fluid} />
            </div>
            <div className="c-cta-contact__inner"
                 data-animate-target
                 data-animate-from={JSON.stringify({y: 20, opacity: 0})}
                 data-animate-to={JSON.stringify({y: 0, opacity: 1, delay: .2})}
            >
                <p className='c-cta-contact__text'>Máte zájem o naše služby?</p>
                <p className='c-cta-contact__button'><button className='o-button o-button--bordered-white' onClick={() => dispatch({ type: 'OPEN_MODAL' })}>Pojďte do toho s námi</button></p>
            </div>
        </div>
    )
};

export default CTAContact
